import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./state/store";

import BootstrapVue3 from "bootstrap-vue-3";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from "vue3-scroll-spy";

import Maska from "maska";
import { i18n } from "./i18n.js";

import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";

import "../src/design/app.scss";
import authUtils from "./authUtils";
import { useRouter } from "vue-router";

createApp(App)
  .use(useRouter())
  .use(store)
  .use(authUtils)
  .use(VueSweetalert2)
  .use(store)
  .use(router)
  .use(require("vue-chartist"))
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .mount("#app");
