
export const state = {
    todos: [],
};

export const getters = {
    todos: state => state.todos,
};

export const mutations = {
    setTodos(state, newValue) {
        state.todos = newValue
    },
};


