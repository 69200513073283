import { createWebHistory, createRouter } from "vue-router";
import routes from './routes'
// eslint-disable-next-line no-unused-vars
import store from '../state/store'


const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.token) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      const tokenExpirationDate = new Date(store.state.exp * 1000) // convertir la marca de tiempo Unix a milisegundos
      const currentDate = new Date()
      console.log(tokenExpirationDate,store.state.exp)
      if (tokenExpirationDate < currentDate) {
        store.commit('clearUserData') // eliminar los datos del state
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    if (to.name === 'login' && store.state.token) {
      next({ path: '/' })
    } else {
      next()
    }
  }
  
  
})

export default router
