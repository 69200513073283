export default [
  {
    path: "/",
    name: "dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/dashboard"),
  },
  {
    path: "/viewAll",
    name: "viewAll",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/viewAll"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {},
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {authRequired:true},
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {authRequired:true},
  },

  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },

  {
    path: "/company",
    name: "company",
    meta: {authRequired:true},
    component: () => import("./views/company/companyList"),
  },
  {
    path: "/new-company",
    name: "new-company",
    meta: {authRequired:true},
    component: () => import("./views/company/newCompany"),
  },
  {
    path: "/client",
    name: "client",
    meta: {authRequired:true},
    component: () => import("./views/clientv/client"),
  },
  {
    path: "/edit-client/:email/:id",
    name: "edit client",
    meta: {
      authRequired:true,
    },
    component: () => import("./views/clientv/clientInfo"),
  },
  
  {
    path: "/Practitioner/Coach/Member",
    name: "Practitioner/Coach/Member",
    meta: { authRequired: true },
    component: () => import("./views/company/coaches"),
  },
  {
    path: "/Billing",
    name: "Billing",
    meta: { authRequired: true },
    component: () => import("./views/company/Billing"),
  },
  {
    path: "/view-invoice",
    name: "franchiseInvoice",
    meta: { authRequired: true },
    component: () => import("./views/company/franchiseInvoice"),
  },
  {
    path: "/intelligence-libraries",
    name: "intelligence-libraries",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/resources",
    name: "Resources",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/messaging",
    name: "Messaging",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/client-App-Admin",
    name: "Client App Admin",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/libraries",
    name: "Libraries",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      authRequired:true,
    },
    component: () => import("./views/account/profile"),
  },
  {
    path: "/companyInfo",
    name: "company Info",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/changePassword",
    name: "Change Password",
    meta: {
      authRequired:true,
    },
    component: () => import("./layouts/underDev"),
  },
  {
    path: "/new-user",
    name: "new user",
    meta: {
      authRequired:true,
    },
    component: () => import("./views/company/addUser"),
  },
  {
    path: "/edit-user/:email/:id",
    name: "edit user",
    meta: {
      authRequired:true,
    },
    component: () => import("./views/company/editUser"),
  },
];
