import { createStore,mapState,mapActions,mapGetters } from 'vuex'

import modules from './modules'


export const authComputed = {
  ...mapState( {
    username: (state) => state.username,
    email: (state) => state.email,
    rol: (state) => state.rol,
    oid: (state) => state.oid,
    token: (state) => state.token,
  }),
};



export const authMethods = mapActions([
  "setUsername",
  "setEmail",
  "setRol",
  "setOid",
  "setToken",
  "logout",
]);
 export const authGetters = mapGetters( [
  "getUsername",
  "getEmail",
  "getRol",
  "getOid",
  "getToken"
 ])

 const username = localStorage.getItem('username')
 const email = localStorage.getItem('email')
 const rol = localStorage.getItem('extension_rol')
 const token = localStorage.getItem('token')
 const oid = localStorage.getItem('oid')
const isActive = localStorage.getItem('isActive')
const exp = localStorage.getItem('exp')

const store = createStore({
  modules,
   state : {
    username: username || null ,
    email:email || null ,
    rol: rol || null ,
    oid:  oid || null ,
    token: token || null ,
    isActive: isActive || false,
    exp:exp || null
  },
  
  
   getters : {
    getUsername: (state) => state.username,
    getEmail: (state) => state.email,
    getRol: (state) => state.rol,
    getOid: (state) => state.oid,
    getToken: (state) => state.token,
    getIsActive: (state) => state.isActive,
    getExp: (state) => state.exp
  },
  
   mutations :{
    setUsername: (state, username) => {
      state.username = username;
    },
    setEmail: (state, email) => {
      state.email = email;
    },
    setRol: (state, rol) => {
      state.rol = rol;
    },
    setOid: (state, oid) => {
      state.oid = oid;
    },
    setToken: (state, token) => {
      state.token = token;
    },
    isActive:(state,status) => {
      state.isActive = status
    },
    setExp:(state,status) => {
      state.exp = status
    },
    clearUserData(state) {
      state.username = null;
      state.email = null;
      state.rol = null;
      state.oid = null;
      state.token = null;
      state.exp = null;
    },
  },
  
   actions : {
    setUsername: ({ commit }, username) => {
      commit("setUsername", username);
    },
    setEmail: ({ commit }, email) => {
      commit("setEmail", email);
    },
    setRol: ({ commit }, rol) => {
      commit("setRol", rol);
    },
    setOid: ({ commit }, oid) => {
      commit("setOid", oid);
    },
    setToken: ({ commit }, token) => {
      commit("setToken", token);
    },
    setStatus:({commit},status) => {
      commit('isActive',status)
    },
    setExp: ({commit},status) => {
      commit('setExp',status)
    },
    logout({ commit }) {
      commit("clearUserData");
    },
  },
  
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production'
})
export default store

